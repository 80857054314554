import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import animalType from '@/store/modules/animalType';
import countries from '@/store/modules/countries'
import feed from '@/store/modules/feed'
import phase from '@/store/modules/phase'
import result from '@/store/modules/result'
import productSelect from '@/store/modules/productSelect'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['auth', 'animalType']
  })],

  state: {
    isNavigation: false,
    isLoading: false
  },

  getters: {
    getLoading: state => state.isLoading
  },

  mutations: {
    setLoading(state, payload) {
      state.isLoading = payload
    },

    // NOTE: need for footer bottom padding, when we have fixed navigation component
    setNavigation(state, payload = false) {
      state.isNavigation = payload
    },
  },

  actions: {
  },

  modules: {
    auth,
    animalType,
    countries,
    feed,
    phase,
    productSelect,
    result
  }
})
