import api from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    selectedProduct: ''
  },

  getters: {
    getSelectedProduct: state => state.selectedProduct,
  },

  mutations: {
    onChangeSelectedProduct(state, payload) {
      state.selectedProduct = payload
    },

    onClearProductSelection(state) {
      state.selectedProduct = ''
    },
  },

  actions: {
    onProductSelection({ commit, dispatch }, { selectedProduct }) {
      return api.post('/product-selection', { 'productSelection': selectedProduct }).then(() => {
        commit('onChangeSelectedProduct', selectedProduct)
        dispatch('feed/onClearDiets', {}, { root: true });
      })
    },

    clearProductSelection({ commit }) {
      commit('onClearProductSelection')
    },

    fetchSelectedProduct({ commit, state }) {
      return state.selectedProduct !== '' ? state.selectedProduct : api.get('/product-selection').then((resp) => {
        commit('onChangeSelectedProduct', resp?.data?.productSelection)
        return resp?.data?.productSelection
      })
    }
  }
}
