export default {
  data: () => ({
    icons: {
      keyboardArrows: {
        down: `
          <svg width="13" height="20" viewBox="0 0 13 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4067 12.0384C11.7773 11.6519 12.3608 11.6519 12.7314 12.0384C13.0895 12.4249 13.0895 13.0335
              12.7314 13.42L7.18042 19.2101C7.00846 19.3894 6.76972 19.5 6.50511 19.5C6.2405 19.5 6.00177 19.3894 5.82898
              19.2101L0.277968 13.42C-0.0926559 13.0335 -0.0926559 12.4249 0.277968 12.0384C0.648591 11.6519 1.23207 11.6519
              1.6027 12.0384L5.56436 16.184V1.46719C5.56353 0.928316 5.98758 0.5 6.50428 0.5C7.02098 0.5 7.43167 0.928316
              7.43167 1.46719V16.1832L11.4067 12.0384Z"/>
          </svg>
        `
      },
      arrows: {
        up: `
          <svg width="14" height="8" viewBox="0 0 14 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.99998 0C6.74907 0 6.49819 0.0960374 6.3069 0.287709L0.287199 6.32224C-0.0957329 6.70612
              -0.0957329 7.3285 0.287199 7.71221C0.669975 8.09593 1.29071 8.09593 1.67367 7.71221L6.99998
              2.37252L12.3263 7.71203C12.7092 8.09574 13.3299 8.09574 13.7127 7.71203C14.0958 7.32831 14.0958
              6.70593 13.7127 6.32206L7.69306 0.287522C7.50167 0.09582 7.25079 0 6.99998 0Z"/>
          </svg>
        `,
        right: `
          <svg width="8" height="14" viewBox="0 0 8 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 7.00002C8 7.25093 7.90396 7.50181 7.71229 7.6931L1.67776 13.7128C1.29388 14.0957 0.671503
              14.0957 0.287787 13.7128C-0.095929 13.33 -0.095929 12.7093 0.287787 12.3263L5.62748 7.00002L0.287974
              1.67369C-0.0957424 1.29075 -0.0957423 0.670083 0.287974 0.287338C0.67169 -0.0957792 1.29407 -0.0957792
              1.67794 0.287338L7.71248 6.30694C7.90418 6.49833 8 6.74921 8 7.00002Z"/>
          </svg>
        `,
        down: `
          <svg data-v-3a119a31="" width="14" height="8" viewBox="0 0 14 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path data-v-3a119a31="" d="M6.99998 8C6.74907 8 6.49819 7.90396 6.3069 7.71229L0.287199 1.67776C-0.0957329 1.29388
              -0.0957329 0.671503 0.287199 0.287787C0.669975 -0.095929 1.29071 -0.095929 1.67367 0.287787L6.99998
              5.62748L12.3263 0.287973C12.7092 -0.0957425 13.3299 -0.0957425 13.7127 0.287973C14.0958 0.67169
              14.0958 1.29407 13.7127 1.67794L7.69306 7.71248C7.50167 7.90418 7.25079 8 6.99998 8Z">

            </path>
          </svg>
        `,
        left: `
          <svg
            viewBox="0 0 9 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="
                M7.34672 0.282546C7.72492 -0.094182 8.33816 -0.094182 8.71636 0.282546C9.09455 0.659284 9.09455
                1.27008 8.71636 1.64682L3.02292 7.31788C2.64473 7.69458 2.64473 8.30542 3.02292 8.68213L8.71636
                14.3532C9.09455 14.7299 9.09455 15.3408 8.71636 15.7175C8.33816 16.0942 7.72492 16.0942 7.34672
                15.7175L0.283667 8.68213C-0.0945556 8.30542 -0.0945556 7.69458 0.283667 7.31788L7.34672 0.282546Z
              "
            />
          </svg>
        `,
      },
      broom: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.2961 0.703651C22.3575 -0.234597 20.8303 -0.234503 19.8918 0.703651L13.4424 7.15045L13.3043
            7.0124C12.105 5.81348 10.1926 5.69634 8.85578 6.7401L0.27524 13.4391C0.1136 13.5653 0.0138581 13.7548
            0.00133768 13.9594C-0.0111828 14.1641 0.0646433 14.3643 0.209684 14.5093L9.49432 23.7903C9.629 23.925
            9.81132 24 10.0005 24C10.0151 24 10.0297 23.9995 10.0443 23.9986C10.2491 23.9861 10.4386 23.8864 10.5648
            23.7248L17.2665 15.1476C18.3105 13.8114 18.1934 11.8997 16.994 10.7008L16.8468 10.5536L23.2961 4.10663C24.2346
            3.16843 24.2346 1.64185 23.2961 0.703651ZM9.93455 22.2059L7.84711 20.1193L10.4136 17.2965C10.6795 17.004 10.6579
            16.5513 10.3653 16.2855C10.0726 16.0196 9.61976 16.0412 9.35383 16.3338L6.83337 19.1059L4.89589 17.1692L7.66915
            14.6497C7.96176 14.3839 7.98338 13.9312 7.71745 13.6387C7.45143 13.3462 6.99872 13.3245 6.70601 13.5904L3.88216
            16.1559L1.79472 14.0692L8.56917 8.78024L15.2256 15.4341L9.93455 22.2059ZM16.1378 14.2664L16.1136 14.2975L9.70618
            7.89248L9.73727 7.86824C10.5048 7.26895 11.6031 7.33621 12.2917 8.02462L15.9814 11.7128C16.6701 12.4013 16.7374
            13.4991 16.1378 14.2664ZM22.2835 3.09441L15.8342 9.54125L14.455 8.16262L20.9043 1.71582C21.2846 1.33571 21.9033
            1.33581 22.2835 1.71582C22.6637 2.09593 22.6637 2.7143 22.2835 3.09441Z"/>
        </svg>
      `,
      trash: `
        <svg width="19" height="22" viewBox="0 0 19 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.9354 4.0789H13.6651V1.4539C13.6651 0.970905 13.2833 0.578918 12.811 0.578918H5.97847C5.50619
            0.578918 5.12442 0.970905 5.12442 1.4539V4.0789H0.854056C0.381769 4.0789 0 4.47089 0 4.95389C0 5.43689
            0.381729 5.82892 0.854056 5.82892H1.70811V18.9539C1.70811 20.4012 2.8577 21.5789 4.27032 21.5789H14.5192C15.9318
            21.5789 17.0814 20.4012 17.0814 18.9539V5.82892H17.9354C18.4077 5.82892 18.7895 5.43693 18.7895 4.95393C18.7895
            4.47093 18.4077 4.0789 17.9354 4.0789ZM6.83253 2.32893H11.9569V4.07895H6.83253V2.32893ZM15.3732 18.9539C15.3732
            19.4361 14.9906 19.8289 14.5192 19.8289H4.27032C3.79887 19.8289 3.41626 19.436 3.41626 18.9539V5.82892H15.3733L15.3732 18.9539Z"
          />
          <path
            d="M6.83253 7.57893C6.36024 7.57893 5.97847 7.97092 5.97847 8.45392V16.3289C5.97847 16.8119 6.36024 17.2039
            6.83253 17.2039C7.30482 17.2039 7.68658 16.8119 7.68658 16.3289V8.45392C7.68658 7.97092 7.30482 7.57893
            6.83253 7.57893Z"
          />
          <path
            d="M11.9569 7.57893C11.4847 7.57893 11.1029 7.97092 11.1029 8.45392V16.3289C11.1029 16.8119 11.4847
            17.2039 11.9569 17.2039C12.4292 17.2039 12.811 16.8119 12.811 16.3289V8.45392C12.811 7.97092 12.4292
            7.57893 11.9569 7.57893Z"
          />
        </svg>
      `,
      pencil: `
        <svg data-v-3a119a31="" width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path data-v-3a119a31="" d="M8.55968 1.96965L1.98891 8.53169L5.46076 11.9989L12.0315 5.43689L8.55968 1.96965Z"></path>
          <path data-v-3a119a31="" d="M13.7913 2.79006L11.2272 0.193025C10.9726 -0.0612306 10.5725 -0.0612306 10.3361 0.174864C10.3361 0.174864 10.3361 0.174864 10.3179 0.193025L9.1904 1.31901L12.6638 4.80594L13.7913 3.67996C14.0459 3.4257 14.0459 3.02616 13.7913 2.79006Z"></path>
          <path data-v-3a119a31="" d="M1.47984 9.29174L0.0432078 13.1419C-0.0840892 13.4688 0.0795783 13.832 0.406913 13.9591C0.552396 14.0136 0.716063 14.0136 0.861545 13.9591L4.71682 12.5063L1.47984 9.29174Z"></path>
        </svg>
      `,
      plus: `
        <svg data-v-3a119a31="" width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path data-v-3a119a31="" d="M7 14C6.8187 14 6.64482 13.928 6.51663 13.7998C6.38843 13.6716 6.31641 13.4977 6.31641
            13.3164V0.683594C6.31641 0.502293 6.38843 0.328419 6.51663 0.20022C6.64482 0.0720213 6.8187 0 7
            0C7.1813 0 7.35518 0.0720213 7.48337 0.20022C7.61157 0.328419 7.68359 0.502293 7.68359
            0.683594V13.3164C7.68359 13.4977 7.61157 13.6716 7.48337 13.7998C7.35518 13.928 7.1813 14 7 14Z">
          </path>
          <path data-v-3a119a31="" d="M13.3164 7.68359H0.683594C0.502293 7.68359 0.328419 7.61157 0.20022 7.48337C0.0720213
            7.35518 0 7.1813 0 7C0 6.8187 0.0720213 6.64482 0.20022 6.51663C0.328419 6.38843 0.502293
            6.31641 0.683594 6.31641H13.3164C13.4977 6.31641 13.6716 6.38843 13.7998 6.51663C13.928 6.64482
            14 6.8187 14 7C14 7.1813 13.928 7.35518 13.7998 7.48337C13.6716 7.61157 13.4977 7.68359 13.3164 7.68359Z"></path>
        </svg>
      `,
      times: `
        <svg width="15" height="14" viewBox="0 0 15 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.22459 7.00011L0.637626 1.40594C0.316044 1.08395 0.316044 0.563493 0.637626 0.241497C0.959208
            -0.0804991 1.47899 -0.0804991 1.80058 0.241497L7.38754 5.83566L12.9745 0.241497C13.2961 -0.0804991
            13.8159 -0.0804991 14.1375 0.241497C14.4591 0.563493 14.4591 1.08395 14.1375 1.40594L8.55051 7.00011L14.1375
            12.5943C14.4591 12.9163 14.4591 13.4367 14.1375 13.7587C13.9771 13.9193 13.7665 14 13.556 14C13.3455 14
            13.1349 13.9193 12.9745 13.7587L7.38757 8.16456L1.8006 13.7587C1.64022 13.9193 1.42967 14 1.21913 14C1.00858
            14 0.798035 13.9193 0.637653 13.7587C0.316072 13.4367 0.316072 12.9163 0.637653 12.5943L6.22459 7.00011Z"
          />
        </svg>
      `,
      search: `
        <svg width="22" height="21" viewBox="0 0 22 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.61408 0C4.43364 0 0.21875 4.14877 0.21875 9.24796C0.21875 14.3474 4.43364 18.4959 9.61408
            18.4959C14.7948 18.4959 19.0094 14.3474 19.0094 9.24796C19.0094 4.14877 14.7948 0 9.61408 0ZM9.61408
            16.7886C5.38994 16.7886 1.95327 13.4059 1.95327 9.248C1.95327 5.09012 5.38994 1.70732 9.61408
            1.70732C13.8382 1.70732 17.2749 5.09008 17.2749 9.24796C17.2749 13.4058 13.8382 16.7886 9.61408 16.7886Z"
          />
          <path
            d="M21.2993 19.5428L16.327 14.6485C15.9882 14.315 15.4395 14.315 15.1007 14.6485C14.7619 14.9817
            14.7619 15.5224 15.1007 15.8556L20.073 20.7499C20.2424 20.9166 20.4641 21 20.6861 21C20.9079 21 21.1299
            20.9166 21.2993 20.7499C21.6381 20.4167 21.6381 19.876 21.2993 19.5428Z"
          />
        </svg>
      `,
      check: `
        <svg width="27" height="25" viewBox="0 0 27 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M25.9256 0.448816C24.7614 -0.314861 23.1258 -0.0788569 22.2742 0.965795L9.06111 17.2353L4.66325
            12.2336C3.76707 11.2178 2.12437 11.0449 0.993892 11.8463C-0.140043 12.6477 -0.330874 14.1264 0.561901
            15.1388L9.22712 25L26.4969 3.72686C27.3486 2.67872 27.0932 1.21249 25.9256 0.448816Z"
          />
        </svg>
      `,
      eye: {
        open: `
          <svg width="20" height="13" viewBox="0 0 20 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.8729 6.10328C19.6942 5.85402 15.4371 0 9.99991 0C4.56275 0 0.305392 5.85402 0.126914 6.10304C-0.0423048
              6.33952 -0.0423048 6.66025 0.126914 6.89672C0.305392 7.14598 4.56275 13 9.99991 13C15.4371 13 19.6942 7.14594
              19.8729 6.89692C20.0424 6.66048 20.0424 6.33952 19.8729 6.10328ZM9.99991 11.6552C5.99486 11.6552 2.52606 7.76975
              1.49922 6.49954C2.52473 5.22822 5.98627 1.34482 9.99991 1.34482C14.0048 1.34482 17.4733 5.22957 18.5006 6.50046C17.4751
              7.77174 14.0136 11.6552 9.99991 11.6552Z"
            />
            <path
              d="M9.99991 2.46552C7.81858 2.46552 6.04385 4.27544 6.04385 6.50002C6.04385 8.7246 7.81858 10.5345 9.99991
              10.5345C12.1812 10.5345 13.956 8.7246 13.956 6.50002C13.956 4.27544 12.1812 2.46552 9.99991 2.46552ZM9.99991
              9.18966C8.54561 9.18966 7.36256 7.98312 7.36256 6.50002C7.36256 5.01692 8.54565 3.81038 9.99991 3.81038C11.4542
              3.81038 12.6373 5.01692 12.6373 6.50002C12.6373 7.98312 11.4542 9.18966 9.99991 9.18966Z"
            />
          </svg>
        `
      },
      attention: `
        <svg width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.6339 11.4711V14.6314M16.6339 20.9519H16.6497M5.68645 27.2724H27.5813C30.0141 27.2724 31.5346 24.6388 30.3182 22.532L19.3708
            3.57048C18.1544 1.46365 15.1134 1.46365 13.897 3.57048L2.94959 22.532C1.73321 24.6388 3.25369 27.2724 5.68645 27.2724Z"
            stroke="#E61F21"
            stroke-width="3.16025"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      `,
      info: `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"/>
        </svg>
      `,
      note: `
        <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 0C2.6916 0 0 2.69164 0 6.00004C0 9.30844 2.6916 12 6 12C9.3084 12 12 9.30844 12 6.00004C12 2.69164 9.3084 0 6 0ZM6 10.9091C3.29309 10.9091 1.09091 8.70691 1.09091 6.00004C1.09091 3.29316 3.29309 1.09091 6 1.09091C8.70691 1.09091 10.9091 3.29316 10.9091 6.00004C10.9091 8.70691 8.70687 10.9091 6 10.9091Z"/>
          <path d="M5.99993 2.54545C5.59898 2.54545 5.2728 2.87185 5.2728 3.27305C5.2728 3.67389 5.59898 4 5.99993 4C6.40087 4 6.72705 3.67389 6.72705 3.27305C6.72705 2.87185 6.40087 2.54545 5.99993 2.54545Z"/>
          <path d="M6 5.09091C5.69876 5.09091 5.45455 5.33513 5.45455 5.63636V8.90909C5.45455 9.21033 5.69876 9.45455 6 9.45455C6.30124 9.45455 6.54545 9.21033 6.54545 8.90909V5.63636C6.54545 5.33513 6.30124 5.09091 6 5.09091Z"/>
        </svg>
      `,
      question: `
        <svg width="14" height="13" viewBox="0 0 14 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.55078 10.2452C6.90135 10.2452 7.18555 9.96105 7.18555 9.61047C7.18555 9.2599 6.90135 8.97571 6.55078 8.97571C6.20021
            8.97571 5.91602 9.2599 5.91602 9.61047C5.91602 9.96105 6.20021 10.2452 6.55078 10.2452Z"/>
          <path
            d="M6.55078 0.00012207C2.95841 0.00012207 0.0507812 2.90727 0.0507812 6.50012C0.0507812 10.0925 2.95793 13.0001 6.55078
              13.0001C10.1431 13.0001 13.0508 10.093 13.0508 6.50012C13.0508 2.90776 10.1436 0.00012207 6.55078 0.00012207ZM6.55078
              11.9845C3.51972 11.9845 1.06641 9.53158 1.06641 6.50012C1.06641 3.46907 3.51932 1.01575 6.55078 1.01575C9.58184 1.01575
              12.0352 3.46866 12.0352 6.50012C12.0352 9.53118 9.58224 11.9845 6.55078 11.9845Z"
            />
          <path
            d="M6.55078 3.26282C5.43075 3.26282 4.51953 4.17404 4.51953 5.29407C4.51953 5.57453 4.74688 5.80188 5.02734
              5.80188C5.30781 5.80188 5.53516 5.57453 5.53516 5.29407C5.53516 4.73405 5.99077 4.27844 6.55078 4.27844C7.1108 4.27844
              7.56641 4.73405 7.56641 5.29407C7.56641 5.85408 7.1108 6.30969 6.55078 6.30969C6.27032 6.30969 6.04297 6.53704 6.04297
              6.8175V8.08704C6.04297 8.3675 6.27032 8.59485 6.55078 8.59485C6.83125 8.59485 7.05859 8.3675 7.05859 8.08704V7.26105C7.93371
              7.035 8.58203 6.23883 8.58203 5.29407C8.58203 4.17404 7.67081 3.26282 6.55078 3.26282Z"
            />
        </svg>
      `,
      padlock: {
        lock: `
          <svg width="15" height="21" viewBox="0 0 15 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.125 7.78846H12.5V5.19231C12.5 2.32868 10.2576 0 7.5 0C4.74243 0 2.5 2.32868 2.5 5.19231V7.78846H1.875C0.841675
                7.78846 0 8.66156 0 9.73558V18.8221C0 19.8961 0.841675 20.7692 1.875 20.7692H13.125C14.1583 20.7692 15 19.8961
                15 18.8221V9.73558C15 8.66156 14.1583 7.78846 13.125 7.78846ZM4.16672 5.19231C4.16672 3.28322 5.66162 1.73082
                7.5 1.73082C9.33838 1.73082 10.8333 3.28322 10.8333 5.19231V7.78846H4.16672V5.19231Z"
            />
          </svg>
        `,
        unlock: `
          <svg width="15" height="21" viewBox="0 0 15 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.125 7.78846H4.16667V5.19231C4.16667 3.28327 5.66167 1.73077 7.5 1.73077C9.33833 1.73077 10.8333 3.28327
                10.8333 5.19231C10.8333 5.67 11.2058 6.05769 11.6667 6.05769C12.1275 6.05769 12.5 5.67 12.5 5.19231C12.5 2.32875
                10.2575 0 7.5 0C4.7425 0 2.5 2.32875 2.5 5.19231V7.78846H1.875C0.841667 7.78846 0 8.66163 0 9.73558V18.8221C0
                19.8961 0.841667 20.7692 1.875 20.7692H13.125C14.1583 20.7692 15 19.8961 15 18.8221V9.73558C15 8.66163 14.1583
                7.78846 13.125 7.78846Z"
              />
          </svg>
        `
      }
    }
  })
}
