import api from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    resultMatrix: [],
    costSavingCurrency: 'USD',
    costSavingCurrencySymbol: '$',
  },

  mutations: {
    setResultMatrix(state, payload) {
      state.resultMatrix = payload
    },
    setCostSavingCurrency(state, payload) {
      state.costSavingCurrency = payload
    },
    setCostSavingCurrencySymbol(state, payload) {
      state.costSavingCurrencySymbol = payload
    },
  },

  actions: {
    fetchResultMatrix({commit, state}, payload) {
      commit('setLoading', true, {root: true})

      return api.post('/diets/actions/calculate-results', payload).then(res => {
        commit('feed/setActiveDiet', res.data.diet, { root: true })
        commit('setResultMatrix', res.data)
        commit('setLoading', false, {root: true})
        return res.data
      })
    },
  }
}
