import api from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    countries: []
  },

  mutations: {
    setCountry(state, countries) {
      state.countries = countries
    }
  },

  actions: {
    fetchCountries({commit}) {
      return api.get('/countries').then(res => {
        commit('setCountry', res.data)
        return res.data
      })
    }
  }
}
