import api from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    diets: [],
    ingredients: [],
    activeDiet: {}
  },

  getters: {
    getDiets: state => state.diets,
    getActiveDiet: state => state.activeDiet,
    getIngredients: state => state.ingredients,
    getIngredientsWithoutMandatory: state => state.ingredients.filter(ingredient => !ingredient.mandatory),
  },

  mutations: {
    onChangeCurrentPhase(state, payload) {
      const currentIdx = state.activeDiet.diet_phases.findIndex(el => el.id === payload.id)
      state.activeDiet.diet_phases[currentIdx] = payload
    },

    addPhaseToActiveDiet(state, payload) {
      state.activeDiet.diet_phases.push(payload)
    },

    removePhaseFromActiveDiet(state, payload) {
      const idx = state.activeDiet.diet_phases.findIndex(phase => {
        return phase.id === payload
      })

      state.activeDiet.diet_phases.splice(idx, 1)
    },

    addDiet(state, payload) {
      // TODO: api call for add, then
      state.diets.push(payload)
    },

    setDiets(state, payload) {
      Array.isArray(payload) ? state.diets = payload : state.diets.push(payload)
    },

    setActiveDiet(state, payload) {
      if (!Object.keys(payload).length) {
        state.activeDiet = payload
        return
      }

      const localActiveDiet = Object.assign({}, payload)

      localActiveDiet.allIngredients = localActiveDiet.diet_phases.flatMap(phase => {
        return [...phase.ingredients]
      })

      localActiveDiet.allIngredients = [
        ...new Map(localActiveDiet.allIngredients.map(item => [item.name, item])).values()
      ].filter(ingredient => !ingredient.mandatory)

      state.activeDiet = localActiveDiet
    },

    setDigestibilityToActiveDiet(state, {val, field}) {
      state.activeDiet.ingredientDigestibility.find(el => el.id === field.id).digestibility = val
    },

    setIngredientToActiveDietAllPhases(state, {ingredient, currentPhaseId}) {
      // NOTE: add ingredient only for current phase
      if (currentPhaseId) {
        const activePhaseIdx = state.activeDiet.diet_phases.findIndex(el => {
          return el.id === currentPhaseId
        })

        state.activeDiet.diet_phases[activePhaseIdx].ingredients.push(JSON.parse(JSON.stringify(ingredient)))
        return
      }

      state.activeDiet.allIngredients.push(JSON.parse(JSON.stringify(ingredient)))

      state.activeDiet.diet_phases.map(diet => {
        diet.ingredients.push(JSON.parse(JSON.stringify(ingredient)))
      })
    },

    unsetIngredientFromActiveDietAllPhases(state, {ingredient, currentPhaseId}) {
      // NOTE: remove ingredient only for current phase
      if (currentPhaseId) {
        const activePhaseIdx = state.activeDiet.diet_phases.findIndex(el => {
          return el.id === currentPhaseId
        })

        state.activeDiet.diet_phases[activePhaseIdx].ingredients = state.activeDiet.diet_phases[activePhaseIdx].ingredients.filter(el => {
          return el.id !== ingredient.id
        })
        return
      }

      state.activeDiet.allIngredients = state.activeDiet.allIngredients.filter(el => {
        return el.id !== ingredient.id
      })

      state.activeDiet.diet_phases.map(phase => {
        phase.ingredients = phase.ingredients.filter(el => {
          return el.id !== ingredient.id
        })
      })
    },

    setIngredients(state, payload) {
      state.ingredients = payload
    },

    deleteDiet(state) {
      state.diets.splice(state.diets.findIndex(diet => diet.id === state.activeDiet.id), 1)
    }
  },

  actions: {
    fetchIngredients({commit, getters}) {
      commit('setLoading', true, {root: true})

      if (!getters.getIngredients.length) {
        return api.get('/ingredients').then(res => {
          res.data.sort((a, b) => {
            if (a.order < b.order) return -1
          })

          commit('setIngredients', res.data)
          return res.data
        })
      }
    },

    fetchDiets({commit, state}) {
      commit('setLoading', true, {root: true})

      return api.get('/diets').then(res => {
        commit('setDiets', res.data.diets)
        return res.data.diets
      })
    },

    onSaveDiet({commit}, diet) {
      return api.post('/diets', diet).then(res => {
        return res.data
      })
    },

    onDeleteDiet({commit, state}) {
      const activeDietName = state.activeDiet.name

      if (String(state.activeDiet.id).includes('temp')) {
        commit('deleteDiet')
        return activeDietName
      }

      return api.delete(`/diets/${state.activeDiet.id}`).then(() => {
        commit('deleteDiet')
        return activeDietName
      })
    },

    onClearDiets({ commit, state }) {
      commit('setDiets', [])
    }
  }
}
