const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    meta: {
      auth: true,
      stepper: 1,
      headerBackButtonProduct: true,
    },
  },
  {
    path: '/product-selection',
    name: 'product-selection',
    component: () => import('@/views/ProductSelection'),
    meta: {
      auth: true
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register')
  },
  {
    path: '/thank-you',
    name: 'thankYou',
    component: () => import('@/views/ThankYou')
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import('@/views/ConfirmEmail')
  },
  {
    path: '/request-verification-email',
    name: 'request-verification-email',
    component: () => import('@/views/RequestVerificationEmail')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  },
  {
    path: '/reset-password/:token?',
    name: 'reset-password',
    props: true,
    component: () => import('@/views/ResetPassword')
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      auth: true,
      headerBackButton: true,
    },
    component: () => import('@/views/MyAccount')
  },
  {
    path: '/calculations',
    name: 'calculations',
    meta: {
      auth: true,
      headerBackButton: true,
    },
    component: () => import('@/views/MyCalculations')
  },
  {
    path: '/calculator',
    name: 'feed-layout',
    meta: {
      auth: true,
      headerBackButtonProduct: true,
    },
    component: () => import('@/views/FeedLayout'),
    children: [
      {
        path: 'feed',
        name: 'feed-view',
        component: () => import('@/views/FeedView'),
        meta: {
          stepper: 2,
          headerBackButtonProduct: true,
        },
      },
      {
        path: 'feed/create',
        name: 'feed-create',
        component: () => import('@/views/FeedCreate'),
        meta: {
          stepper: 2,
          headerBackButtonProduct: true,
        }
      },
      {
        path: 'phases',
        name: 'phases',
        component: () => import('@/views/Phase'),
        meta: {
          stepper: 3,
          headerBackButtonProduct: true,
        },
      },
      {
        path: 'result',
        name: 'result',
        component: () => import('@/views/Result'),
        meta: {
          stepper: 4,
          headerBackButtonProduct: true,
        },
        children: [
          {
            path: 'export'
          },
        ]
      },
    ]
  },
]

export default routes;
