const getDefaultState = () => {
  return {
    animalTypes: [
      {
        id: 1,
        isActive: false,
        disabled: false,
        type: 'meatPoultry',
        name: 'Broilers',
        imgSrc: require('@/assets/images/animals/meat-poultry.png'),
        imgSrcProact: require('@/assets/images/animals/broiler-blue.png'),
        tooltip: 'This selection can be used for all meat-producing poultry species (e.g. turkey, ducks, quails)'
      },
      {
        id: 2,
        isActive: false,
        disabled: false,
        type: 'eggPoultry',
        name: 'Laying Hens',
        imgSrc: require('@/assets/images/animals/egg-poultry.png'),
        imgSrcProact: require('@/assets/images/animals/layers-blue.png'),
        tooltip: 'This selection can be used for all egg-producing poultry species (e.g. ducks, quails, breeders)'
      },
      {
        id: 3,
        isActive: false,
        disabled: false,
        type: 'swine',
        name: 'Swine',
        imgSrc: require('@/assets/images/animals/swine.png'),
        imgSrcProact: require('@/assets/images/animals/swine-blue.png'),
        tooltip: null
      },
      {
        id: 4,
        isActive: false,
        disabled: false,
        type: 'aqua',
        name: 'Fish',
        imgSrc: require('@/assets/images/animals/aqua.png'),
        imgSrcProact: require('@/assets/images/animals/aqua-blue.png'),
        tooltip: null
      },
    ],
    animalType: ''
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setActiveAnimalType(state, payload) {
      state.animalTypes.forEach(el => {
        el.isActive = false
      })

      state.animalTypes.find(el => el.type === payload).isActive = true
    },

    resetState(state) {
      Object.assign(state, getDefaultState())
    }
  },

  getters: {
    animalTypeById: (state) => (id) => state.animalTypes.find(el => el.id === id),
    isActiveMeatPoultry(state, getters) {
      return getters.getActiveAnimalType.type === 'meatPoultry'
    },
    isActiveEggPoultry(state, getters) {
      return getters.getActiveAnimalType.type === 'eggPoultry'
    },
    getActiveAnimalType(state) {
      const activeAnimalType = state.animalTypes.find(el => el.isActive)

      if (!activeAnimalType) {
        return
      }

      return activeAnimalType
    },
    getActiveRatioByAnimalType(state, getters) {
      const ratios = {
        meatPoultry: 'ratio_broilers',
        eggPoultry: 'ratio_layers',
        swine: 'ratio_swine',
        aqua: 'ratio_aqua',
      };
      return ratios[getters.getActiveAnimalType.type] || ''
    }
  },

  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
  }
}
