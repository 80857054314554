<template>
  <div
    id="app"
    ref="app"
    class="wrapper font-sans min-h-screen flex flex-col"
    :class="[$route.name, getSelectedProduct === 'proact' ? 'theme-proact' : 'theme-proact360']"
  >
    <Header/>

    <div v-if="isLoading" class="loader text-white flex flex-1 items-center justify-center">
      <svg class="v-else animate-spin h-20 w-20 text-brand-dark" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>

    <div v-show="!isLoading" :class="classObject">
      <Stepper
        v-if="$route.meta.stepper"
        :step="$route.meta.stepper"
      />

      <router-view/>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Stepper from '@/components/Stepper'
import {mapGetters} from 'vuex';

export default {
  components: {
    Header,
    Stepper,
    Footer
  },

  computed: {
    ...mapGetters({
      isLoading: 'getLoading',
      getSelectedProduct: 'productSelect/getSelectedProduct'
    }),

    classObject() {
      return {
        'container mx-auto mb-auto': this.$route.name !== 'product-selection',
        '3xl:container 3xl:mx-auto 3xl:mb-auto': this.$route.name === 'product-selection'
      }
    }
  }
}
</script>
