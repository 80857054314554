<template>
  <div class="flex justify-center mt-6 md:mt-16 mb-16">
    <nav aria-label="Progress" class="w-full">
      <ol class="flex justify-around md:justify-center items-center text-center md:space-x-6 lg:space-x-12">
        <template
          v-for="(config, configKey) in configBySteps"
        >
          <li
            class="step-item flex-1 md:flex-none"
            :class="{ 'is-active-link': isActiveLink(config.method), [config.classTagLi]: true }"
            :key="`step-${configKey}`"
            @click="onClickStep(config)"
          >
            <div
              :ref="config.refName"
              class="before:bg-brand-red"
              :class="[getClassesStep(configKey + 1), { 'bg-brand-semiMedium': config.state === 'completed', 'bg-brand-lightest': config.state !== 'completed', } ]"
            ></div>
            <p class="text-xs md:text-base font-medium">
              {{ config.text }}
            </p>
          </li>

          <li
            v-if="(configKey + 1) < configBySteps.length"
            :key="`step-spacer-${configKey}`"
            class="spacer mb-8 w-6 md:w-16 lg:w-20 h-2"
          ></li>
        </template>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Stepper',

  props: {
    step: {
      type: Number,
      default: 1
    }
  },

  data: () => ({
    configBySteps: [
      {
        url: {name: 'home'},
        method: 'getLinkStepOne',
        classTagLi: 'type',
        text: 'Animal Type',
        refName: 'stepOne',
        state: 'current',
      },
      {
        url: {name: 'feed-view'},
        method: 'getLinkStepTwo',
        classTagLi: 'feed',
        text: 'Feed Input',
        refName: 'stepTwo',
        state: 'inactive',
      },
      {
        url: {name: 'phases'},
        method: 'getLinkStepThree',
        classTagLi: 'phases',
        text: 'Phases',
        refName: 'stepThree',
        state: 'inactive',
      },
      {
        url: {name: 'result'},
        method: 'getLinkStepFour',
        classTagLi: 'result',
        text: 'Result',
        refName: 'stepFour',
        state: 'inactive',
      },
    ]
  }),

  methods: {
    getClassesStep(step) {
      const classList = ['step', 'rounded-full']

      if (this.step === step) {
        classList.push('current')
      } else if (step < this.step) {
        classList.push('completed')
      } else {
        classList.push('not-selected')
      }

      return classList.join(' ')
    },

    isActiveLink(method) {
      return this[method]
    },

    hasStepCompleted(step) {
      return this.getClassesStep(step).includes('completed')
    },

    hasStepCurrent(step) {
      return this.getClassesStep(step).includes('current')
    },

    onClickStep(config) {
      const url = this[config.method]

      if (url) {
        this.$router.push(url)
      }
    }
  },

  computed: {
    getLinkStepOne() {
      const { url } = this.configBySteps[0];
      if (this.hasStepCompleted(1)) {
        return url;
      }

      if (this.hasStepCurrent(1)) {
        return null
      }

      return null
    },
    getLinkStepTwo() {
      const { url } = this.configBySteps[1];
      if (this.hasStepCompleted(2)) {
        return url;
      }

      if (this.hasStepCurrent(2)) {
        return null
      }

      return this.$store.getters['animalType/getActiveAnimalType'] &&
        Object.keys(this.$store.state.feed.activeDiet).length &&
        Object.keys(this.$store.state.result.resultMatrix).length ? url : null
    },
    getLinkStepThree() {
      const { url } = this.configBySteps[2];
      if (this.hasStepCompleted(3)) {
        return url;
      }

      if (this.hasStepCurrent(3)) {
        return null
      }

      return this.$store.getters['animalType/getActiveAnimalType'] &&
        Object.keys(this.$store.state.feed.activeDiet).length &&
        Object.keys(this.$store.state.result.resultMatrix).length ? url : null
    },
    getLinkStepFour() {
      const { url } = this.configBySteps[3];
      if (this.hasStepCompleted(4)) {
        return url;
      }

      if (this.hasStepCurrent(4)) {
        return null
      }

      return Object.keys(this.$store.state.result.resultMatrix).length ? url : null
    },
  },

  watch: {
    step: function(val) {
      for (const [i, value] of this.configBySteps.entries()) {
        if (i === val - 1) {
          value.state = 'current'
        } else if (i < val - 1) {
          value.state = 'completed'
        } else {
          value.state = 'inactive'
        }
      }
    }
  }
};
</script>

<style scoped>
.step {
  @apply flex flex-col items-center justify-center mb-4 bg-no-repeat bg-center relative w-8 h-8 md:w-14 md:h-14 lg:w-70px lg:h-70px rounded-full ml-auto mr-auto
}

.completed {
  @apply text-white
}

.current {
  @apply bg-white p-1 md:p-2.5 bg-white shadow
}

.is-active-link:hover {
  cursor: pointer;
}

.step:before {
  content: '';
  display: block;
  position: absolute;
  mask-repeat: no-repeat;
  mask-position: center center;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--color-dark);
}

.current:after {
  content: '';
  display: block;
  position: absolute;
  background-color: var(--color-dark);
  border-radius: 100%;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
}

.step.completed:before,
.step.current:before {
  mask-size: 15px;
  background-color: #fff;
}

.step:not(.current):not(.completed):before {
  mask-size: 60%;
}

.type .step:not(.completed):before {
  mask-image: url('~@/assets/images/icons/type.svg');
}

.feed .step:not(.completed):before {
  mask-image: url('~@/assets/images/icons/ingredients.svg');
}

.phases .step:not(.completed):before {
  mask-image: url('~@/assets/images/icons/calculator.svg');
}

.result .step:not(.completed):before {
  mask-image: url('~@/assets/images/icons/checklist.svg');
}

.completed:before{
  mask-image: url('~@/assets/images/icons/completed.svg');
}

.spacer {
  background: url('~@/assets/images/icons/stepper-dots.svg') no-repeat center center;
  background-size: contain;
}

@screen md {
  .current:after {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }

  .step.current:before,
  .step.completed:before {
    mask-size: 25px;
  }
}

@screen lg {
  .step.current:before {
    mask-size: 38px;
  }

  .step:not(.current):before {
    mask-size: inherit;
  }
}

</style>
