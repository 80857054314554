import api from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    nutrients: [],
    phaseResult: []
  },

  mutations: {
    setNutrients(state, payload) {
      state.nutrients = payload
    },

    setPhaseResult(state, payload) {
      state.phaseResult = payload || []
    }
  },

  actions: {
    fetchNutrients({commit, state}) {
      commit('setLoading', true, {root: true})

      if (!state.nutrients.length) {
        return api.get('/nutrients').then(res => {
          commit('setNutrients', res.data)
          return res.data
        })
      }
    },

    onCalculatePhaseMatrix({commit}, {phase, ingredientDigestibility, animal}) {
      return api.post('/diets/actions/calculate-phase-matrix', {phase, ingredientDigestibility, animal}).then(res => {
        commit('setPhaseResult', res.data)
        return res.data
      }).catch(() => {
        commit('setPhaseResult', null)
      })
    }
  }
}
