import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import {checkAccessMiddleware} from './middlewares';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(checkAccessMiddleware)

export default router
