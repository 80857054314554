import api from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    lastTimeGetUser: null
  },

  getters: {
    authenticated(state) {
      return state.authenticated
    },

    user(state) {
      return state.user
    },

    hasProactAccess(state, getters) {
      return getters.user.is_proact
    },

    hasProact360Access(state, getters) {
      return getters.user.is_proact360
    },
  },

  mutations: {
    SET_USER_DATA(state, {isAuth, userData}) {
      state.authenticated = isAuth
      state.user = userData
      const dt = new Date();
      dt.setHours(dt.getHours() + 1);
      state.lastTimeGetUser = dt.getTime();
    },
    setUser(state, payload) {
      state.user = payload
    }
  },

  actions: {
    signIn({ dispatch, commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        api.get('/sanctum/csrf-cookie')
            .then(() => {
              return api.post('/login', {email, password})
                  .then(() => {
                    resolve(dispatch('me'))
                  })
                  .catch((err) => {
                    // TODO: fix after success api status, now /login is 302
                    reject(err.response)
                  })
            })
      })
    },

    signUp({ dispatch, commit }, formData) {
      return new Promise((resolve, reject) => {
        api.get('/sanctum/csrf-cookie')
            .then(() => {
              return api.post('/register', formData)
                  .then(() => {
                    resolve()
                  })
                  .catch((err) => {
                    // TODO: fix after success api status, now /login is 302
                    reject(err.response)
                  })
            })
      })
    },

    signOut({ commit, dispatch }) {
      console.log('signOut');
      return new Promise((resolve, reject) => {
        api.post('/logout').then((res) => {
          commit('SET_USER_DATA', {
            isAuth: false,
            userData: null
          })
          dispatch('productSelect/clearProductSelection', {}, { root: true })
          return resolve()
        }).catch(err => {
          if (err.response.status === 401) {
            commit('SET_USER_DATA', {
              isAuth: false,
              userData: null
            })
          }
          console.log(err)
          return reject(err)
        })
      })
    },

    me({ commit }) {
      return api.get('/user')
          .then((response) => {
            commit('SET_USER_DATA', {
              isAuth: true,
              userData: response.data
            })

            return response.data
          })
          .catch((err) => {
            commit('SET_USER_DATA', {
              isAuth: false,
              userData: null
            })

            return err
          })
    },
  }
}
