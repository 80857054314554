<template>
  <header class="header py-2 md:py-3 lg:py-4 border-b border-gray-100" id="header">
    <div class="container mx-auto">
      <div class="flex justify-between items-center lg:justify-start md:space-x-10">
        <div class="flex-1">
          <router-link
            class="inline-block align-middle"
            :to="{name: 'home'}"
          >
            <img src="@/assets/images/logo.png" class="w-16 md:w-28 xl:w-auto" alt="DSM">
          </router-link>
        </div>

        <span v-if="selectedProduct === 'proact360'" class="hidden md:block xl:hidden">
          <img class="w-40" src="@/assets/images/logo-proAct.svg" alt="ProAct">
        </span>
        <span v-if="selectedProduct === 'proact'" class="hidden md:block xl:hidden">
          <img class="w-40" src="@/assets/images/logo-ronozyme.png" alt="ProAct">
        </span>

        <div
          class="xl:hidden"
        >
          <button
            class="h-5 w-5 focus:outline-none"
            type="button"
            aria-expanded="false"
            ref="button"
            @click="toggleMenu"
          >
            <svg v-if="!isOpen" class="fill-current" width="18" height="12" viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0.582104C0 0.42772 0.0592632 0.27966 0.164752 0.170494C0.270242 0.0613285 0.413316 0 0.5625
              0H17.4375C17.5867 0 17.7298 0.0613285 17.8352 0.170494C17.9407 0.27966 18 0.42772 18 0.582104C18 0.736487
              17.9407 0.884548 17.8352 0.993713C17.7298 1.10288 17.5867 1.16421 17.4375 1.16421H0.5625C0.413316 1.16421
              0.270242 1.10288 0.164752 0.993713C0.0592632 0.884548 0 0.736487 0 0.582104ZM17.4375 5.4179H0.5625C0.413316
              5.4179 0.270242 5.47922 0.164752 5.58839C0.0592632 5.69756 0 5.84562 0 6C0 6.15438 0.0592632 6.30244 0.164752
              6.41161C0.270242 6.52078 0.413316 6.5821 0.5625 6.5821H17.4375C17.5867 6.5821 17.7298 6.52078 17.8352
              6.41161C17.9407 6.30244 18 6.15438 18 6C18 5.84562 17.9407 5.69756 17.8352 5.58839C17.7298 5.47922 17.5867
              5.4179 17.4375 5.4179ZM9 10.8358H0.5625C0.413316 10.8358 0.270242 10.8971 0.164752 11.0063C0.0592632 11.1155
              0 11.2635 0 11.4179C0 11.5723 0.0592632 11.7203 0.164752 11.8295C0.270242 11.9387 0.413316 12 0.5625 12H9C9.14918
              12 9.29226 11.9387 9.39775 11.8295C9.50324 11.7203 9.5625 11.5723 9.5625 11.4179C9.5625 11.2635 9.50324 11.1155
              9.39775 11.0063C9.29226 10.8971 9.14918 10.8358 9 10.8358Z"/>
            </svg>

            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <nav class="hidden xl:flex xl:w-auto">
          <div class="2xl:text-xl font-medium flex-grow flex items-center justify-end">
            <template v-if="isLogin">
              <router-link
                v-if="$route.meta.headerBackButton"
                class="mx-2 xl:mx-4 2xl:mx-6 text-brand-medium"
                :to="{name: 'home'}"
              >
                <span class="flex items-center">
                  <span
                    class="icon inline-block mr-3 w-2"
                    v-html="icons.arrows.left"
                  />

                  Back to Calculator
                </span>
              </router-link>

              <div
                v-if="$route.meta.headerBackButtonProduct"
                class="mx-2 xl:mx-4 2xl:mx-6 text-brand-medium"
                @click="onBackToProductSelectClick"
              >
                <span class="flex items-center">
                  <span
                    class="icon inline-block mr-3 w-2"
                    v-html="icons.arrows.left"
                  />

                  Back to product selection
                </span>
              </div>

              <router-link
                :to="{name: 'calculations'}"
                class="mx-2 xl:mx-4 2xl:mx-6"
                v-if="$route.name !== 'product-selection'"
              >
                My Calculations
              </router-link>

              <router-link
                :to="{name: 'account'}"
                class="mx-2 xl:mx-4 2xl:mx-6"
              >
                My Account
              </router-link>
              <span
                v-if="user.type === 'Super Admin' || user.type === 'Administrator'"
                @click="openAdmin"
                class="mx-2 xl:mx-4 2xl:mx-6 admin__link"
              >
                Administration
              </span>
            </template>

            <template v-else>
              <router-link
                :to="{name: 'register'}"
                class="mx-2 xl:mx-4 2xl:mx-6"
              >
                Register
              </router-link>
            </template>

            <router-link
              v-if="!$store.getters['auth/authenticated']"
              :to="{name: 'login'}"
              class="btn mx-2 xl:mx-4 2xl:mx-6 text-medium rounded-full bg-grey-lightest ring-grey-lightest text-brand-dark"
            >
              Login
            </router-link>

            <button
              v-else
              class="btn mx-2 xl:mx-4 2xl:mx-6 text-medium rounded-full bg-grey-lightest ring-grey-lightest text-brand-dark"
              @click="onLogout"
            >
              Logout
            </button>

            <img v-if="selectedProduct === 'proact360'" src="@/assets/images/logo-proAct.svg" alt="ProAct360" class="w-40 2xl:w-auto block ml-4 2xl:ml-6">
            <img v-if="selectedProduct === 'proact'" src="@/assets/images/logo-ronozyme.png" alt="ProAct" class="w-40 2xl:w-auto block ml-4 2xl:ml-6">
          </div>
        </nav>
      </div>
    </div>

    <!--
      Mobile menu, show/hide based on mobile menu state.

      Entering: "duration-200 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->

    <div
      v-toggle="{
        exclude: ['button'],
        handler: 'closeMenu'
      }"
      class="absolute top-12 md:top-20 md:-mt-1 inset-x-0 transition transform origin-top-right xl:hidden"
      :class="`${isOpen ? 'z-50' : '-z-10'} shadow-lg ease-${isOpen ? 'out' : 'in'} duration-${isOpen ? '100' : '75'} opacity-${isOpen ? '100' : '0'} scale-${isOpen ? '100' : '95'}`"
    >
      <div class="ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div class="container mx-auto pt-5">
          <nav class="flex flex-col text-center md:text-left">
            <div class="mb-4 md:hidden">
              <img src="@/assets/images/logo-proAct.svg" alt="ProAct" class="inline-block w-36">
            </div>

            <div class="flex flex-col divide-y divide-mediumLight divide-dashed">
              <router-link
                v-if="$route.meta.headerBackButton"
                class="text-brand-medium py-5"
                :to="{name: 'home'}"
                @click.native="closeMenu"
              >
                <span class="flex items-center justify-center md:justify-start">
                  <span
                    class="icon inline-block mr-3 w-2"
                    v-html="icons.arrows.left"
                  />

                  Back to Calculator
                </span>
              </router-link>

              <template v-if="isLogin">
                <router-link
                  class="py-5"
                  :to="{name: 'calculations'}"
                  @click.native="closeMenu"
                  v-if="$route.name !== 'product-selection'"
                >
                  <span class="text-base font-medium">
                    My Calculations
                  </span>
                </router-link>

                <router-link
                  class="py-5"
                  :to="{name: 'account'}"
                  @click.native="closeMenu"
                >
                  <span class="text-base font-medium">
                    My Account
                  </span>
                </router-link>

                <span
                  class="py-5 text-base font-medium admin__link"
                  @click="openAdmin"
                  v-if="user.type === 'Super Admin' || user.type === 'Administrator'"
                >
                    Administration
                </span>
              </template>

              <template v-else>
                <router-link
                  class="py-5"
                  :to="{name: 'register'}"
                  @click.native="closeMenu"
                >
                  <span class="text-base font-medium">
                    Register
                  </span>
                </router-link>
              </template>

              <router-link
                v-if="!$store.getters['auth/authenticated']"
                class="py-5"
                :to="{name: 'login'}"
                @click.native="closeMenu"
              >
                <span class="text-base font-medium">
                  Login
                </span>
              </router-link>

              <button
                v-else
                class="py-5"
                @click="onLogout"
              >
                <span class="text-base font-medium">
                  Logout
                </span>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: 'Header',

  data: () => ({
    isOpen: false,
    urlAdmin: ''
  }),
  created() {
    this.urlAdmin = process.env.VUE_APP_API_URL_ADMIN;
  },

  methods: {
    ...mapActions({
      clearProductSelection: 'productSelect/clearProductSelection',
    }),

    toggleMenu() {
      this.isOpen = !this.isOpen
    },

    openMenu() {
      this.isOpen = true
    },

    closeMenu() {
      this.isOpen = false
    },

    onLogout() {
      this.$store.dispatch('auth/signOut').then(() => {
        this.$router.push({name: 'login'})
      })
    },

    openAdmin() {
      location.href = this.urlAdmin;

      this.closeMenu();
    },

    onBackToProductSelectClick() {
      this.clearProductSelection().then(() => {
        this.$router.push({name: 'product-selection'})
      })
    }
  },

  computed: {
    isLogin() {
      const enableRouteNames = ['login', 'register', 'reset-password', 'request-verification-email', 'confirm-email']
      return !enableRouteNames.includes(this.$route.name)
    },

    ...mapState({
      user: state => state?.auth?.user || {},
      selectedProduct: state => state?.productSelect?.selectedProduct || {}
    }),
  }
};
</script>

<style scoped>
  .router-link-exact-active {
    @apply text-brand-semiMedium
  }

  .admin__link {
    cursor: pointer;
  }
</style>
