import Vue from 'vue'
import App from './App.vue'
import '@/registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/styles/main.css'
import '@/plugins/axios'
import toggleDirective from '@/directives/toggle.directive'
import IconsMixin from '@/mixins/IconsMixin'
import GetIndex from '@/mixins/GetIndex'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import VTooltip from 'v-tooltip'
import Hotjar from 'vue-hotjar'
import VueMask from 'v-mask'
import SlitherSlider from 'slither-slider'


Vue.config.productionTip = false
Vue.directive('toggle', toggleDirective)
Vue.mixin(IconsMixin)
Vue.mixin(GetIndex)
Vue.use(VueToast, {
  position: 'top-right'
});
Vue.use(SlitherSlider)
Vue.use(VTooltip, {
  defaultTrigger: /Mobi|Android/i.test(navigator.userAgent) ? 'click' : 'hover focus'
})
Vue.use(Hotjar, {
  id: process.env.HOTJAR_SITE_ID,
  isProduction: process.env.HOTJAR_IS_PRODUCTION,
})
Vue.use(VueMask);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
