<template>
  <footer
    :class="[($store.state.isNavigation && !$store.state.isLoading) ? 'pb-16 md:pb-20' : '', 'mt-5 md:mt-0 footer']"
  >
    <div v-if="$route.name !== 'product-selection'" class="pre-footer relative">
      <div class="md:-mb-10"></div>

      <img v-show="getSelectedProduct === 'proact360' || isLogin" src="@/assets/images/footer_bg-new.png" alt="Footer Background" class="mx-auto">
      <img v-show="getSelectedProduct === 'proact' && !isLogin" src="@/assets/images/footer_bg_proact.jpg" alt="Footer Background" class="mx-auto">

      <div class="container mx-auto text-center xl:text-left text-xs md:text-sm text-white">
        <p class="uppercase absolute bottom-3 md:bottom-7 left-0 right-0 xl:left-auto xl:right-auto">
          Nutrition &#8226; Health &#8226; Sustainable living
        </p>
      </div>
    </div>
    <div class="bg-white">
      <div class="container mx-auto text-sm py-4 xl:py-8">
        <div class="flex flex-col md:flex-row md:justify-between">
          <div class="flex items-center justify-center md:order-2 divide-x divide-current">
            <router-link to="/" class="px-5">
            <span>
              Disclaimer
            </span>
            </router-link>

            <router-link to="/" class="px-5">
            <span>
              Privacy Policy
            </span>
            </router-link>
          </div>

          <div class="mt-3.5 order-0 md:mt-0">
            <p class="text-center">
              &copy; DSM Nutritional Products Ltd 2021
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Footer',

  computed: {
    ...mapGetters({
      getSelectedProduct: 'productSelect/getSelectedProduct'
    }),

    isLogin() {
      const enableRouteNames = ['login', 'register', 'reset-password', 'request-verification-email', 'confirm-email', 'product-selection']
      return enableRouteNames.includes(this.$route.name)
    },
  },
};
</script>

<style scoped>
</style>
