import Vue from 'vue';
import axios from 'axios';
import store from '@/store'
import router from '@/router'

const baseDomain = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : ``;

const api = axios.create({
  withCredentials: true,
  baseURL: baseDomain,
})

api.defaults.headers.get.Accept = 'application/json';

// Add a request interceptor
api.interceptors.request.use(function(config) {
  // TODO: Place for token

  // Do something before request is sent
  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(function(response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function(error) {
  // console.log(error, error.response);
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response && (error.response.status === 419 || (error.response.status === 401 && error.config.url !== '/logout'))) {
    store.dispatch('auth/signOut').then(() => {
      // console.log('then');
      router.push({name: 'login'});
    });
  } else if (error.response.status === 401) {
    // local sign out
    store.commit('auth/SET_USER_DATA', {
      isAuth: false,
      userData: null
    });
    router.push({ name: 'login' });
  }
  return Promise.reject(error);
});

Vue.prototype.$api = api

export default api;
