import store from '@/store'

export async function checkAccessMiddleware(to, from, next) {
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (store.getters['auth/authenticated'] && (store.state.auth.lastTimeGetUser < (new Date()).getTime())) {
    await store.dispatch('auth/me')
  }

  if (!requireAuth && store.getters['auth/authenticated']) {
    next({name: 'home'})
  }

  if (requireAuth && !store.getters['auth/authenticated']) {
    next({name: 'login'})
  } else {
    next()
  }
}
